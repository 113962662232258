const spacing = {
  xs: '.25rem',
  sm: '.5rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
  xxl: '2.5rem',
};

export default spacing;
